<template>
	<b-row>
		<b-col>
			<b-alert
				show
				variant="warning">
				<b-row>
					<b-col class="text-center">
						<h3>{{ translate('account_transfer_fee_title') }}</h3>
						<p>{{ translate('account_transfer_fee_text') }}</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-center">
						<button
							class="btn btn-primary"
							@click="$router.push({ name: 'TransferFee' })">
							{{ translate('account_transfer_fee_button') }}
						</button>
					</b-col>
				</b-row>
			</b-alert>
		</b-col>
	</b-row>
</template>

<script>

import { AccountTransferFee } from '@/translations';
import { distributor } from '@/settings/Roles';

export default {
	name: 'AccountTransferFee',
	messages: [AccountTransferFee],
	data() {
		return {
			distributor,
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (!vm.$user.details().pay_transfer_account_fee) vm.$router.replace({ name: 'MainHome' });
		});
	},
};
</script>
